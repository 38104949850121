import React, { useContext } from 'react';
import { Button, Result } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { RobotOutlined } from '@ant-design/icons';

import { AppContext } from '../../context';

import './styles.scss';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const location = useLocation();

  const currentParentPath = location.pathname.split('/')[1];
  const { logErrorToBackend } = useContext(AppContext);

  logErrorToBackend(error, `ErrorBoundaryPage - ${currentParentPath || ''}`);

  return (
    <div className='error-page-container'>
      <Result
        icon={<RobotOutlined />}
        title='Something problem'
        subTitle='Dont worry, we will fixit soon.'
        extra={<Button onClick={() => resetErrorBoundary()}>Start over</Button>}
      />
    </div>
  );
};

const ErrorBoundary = ({ children }) => {
  const navigate = useNavigate();
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() =>
        navigate('/start', {
          replace: true,
        })
      }
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
