export const RQ_BTC_PRICES = "RQ_BTC_PRICES";
export const RQ_HASHRATE_HISTORY = "RQ_HASHRATE_HISTORY";
export const RQ_MINER_INFORMATION = "RQ_MINER_INFORMATION";
export const RQ_CHECK_ACCOUNT = "RQ_CHECK_ACCOUNT";
export const RQ_CHECK_TRANSACTION_STATUS = "RQ_CHECK_TRANSACTION_STATUS";
export const RQ_PROFITABLE_CHART = "RQ_PROFITABLE_CHART";

export const currencySelectList = [
  {
    value: "CKBTC",
    label: "ckBTC (ICP)",
  },
  {
    value: "USDT",
    label: "USDT (Arbitrum)",
    disabled: true,
  },
  {
    value: "IDR",
    label: "IDR (Fiat)",
    // disabled: true,
  },
];

export const availableBank = {
  BCA: "BCA",
  MANDIRI: "MANDIRI",
  BNI: "BNI",
};

export const transactionStatus = {
  RECEIVED: "Requested",
  WAITING_FOR_DISBURSEMENT: "Pending",
  DISBURSED: "Success",
  REJECTED: "Failed",
  DISBURSEMENT_FAILED: "Failed",
  "NOT FOUND": "Invalid",
};
