import React, { createContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ConfigProvider, message } from "antd";

import OpenLogin from "@toruslabs/openlogin";

import {
  lokaMinerAgentCreation,
  ckBTCAgentCreation,
  getUserIdentity,
} from "../service/canister";

import { handleLogin } from "../service/auth";

import { storeLog } from "../service/mining";

import { normalizeMinerData } from "../helpers/normalize";

import smallIcon from "../assets/images/loka_icon.png";

export const AppContext = createContext({});

const openLoginConfig = {
  clientId: process.env.REACT_APP_OPEN_LOGIN_CLIENT_ID,
  network: process.env.REACT_APP_OPEN_LOGIN_NETWORK,
  uxMode: "popup",
};

// color variabel
const black = "#3d3c3d";
const grey = "#73888c";
const mediumGrey = "#373d43";
const lightGrey = "#e8e3da";
const darkGrey = "#2c2f33";
const primary = "#eca400";
const red = "#f74b37";
const green = "#66c61c";
const orange = "#f7931a";

const themeProvider = {
  token: {
    black,
    grey,
    darkGrey,
    primary,
    red,
    green,
    lightGrey,
    mediumGrey,
    darkGrey,
    orange,
    fontFamily: "Inter",
    Button: {
      fontWeight: 600,
      primaryColor: "white",
      border: "border: 1px solid #7F56D9",
      borderColorDisabled: "border: 1px solid #7F56D9",
    },
  },
  components: {
    Radio: {
      buttonBg: "rgba(255, 255, 255, 0.3)",
      buttonSolidCheckedBg: "rgba(255, 255, 255, 0.1)",
      buttonSolidCheckedActiveBg: "rgba(255, 255, 255, 0.1)",
      buttonSolidCheckedHoverBg: "rgba(255, 255, 255, 0.1)",
    },
    Tabs: {
      itemSelectedColor: "#134E48",
      inkBarColor: "#134E48",
      itemHoverColor: "#134E48",
      itemColor: "#134E48",
    },
    Button: {
      fontWeight: 500,
      defaultBorderColor: primary,
    },
    Layout: {
      siderBg: "#898989",
      colorBgHeader: "rgba(150, 150, 150, 0.7)",
      colorBgLayout: "rgba(0, 0, 0, 0.80)",
      headerPadding: 0,
    },
    Menu: {
      itemColor: "white",
      itemDisabledColor: "#aaaaaa",
      itemSelectedBg: "#040303",
      itemActiveBg: "rgba(0, 0, 0, 0.2)",
      itemHoverBg: "rgba(0, 0, 0, 0.2)",
      itemHoverColor: "white",
      itemBg: "transparent",
      itemSelectedColor: red,
      fontSize: 16,
      iconSize: 18,
      itemHeight: 48,
      itemPaddingInline: 22,
    },
  },
};

export const AppProvider = ({ children }) => {
  const [web3AuthInstance, setWeb3AuthInstance] = useState();
  const [web3AuthPrivKey, setWeb3AuthPrivKey] = useState();
  const [lokaMinerAgent, setLokaMinerAgent] = useState();
  const [ckBTCAgent, setCkBTCAgent] = useState();
  const [userInfo, setUserInfo] = useState();
  const [loginLoading, setLoginLoading] = useState(false);
  const [bitCoinPrices, setBitCoinPrices] = useState(0);

  // handlle connect to web3auth via login modal
  const handleConnectWeb3Auth = async () => {
    try {
      const { privKey } = await web3AuthInstance.login({
        loginProvider: "google",
        redirectUrl: `${window.origin}`,
      });

      if (privKey) {
        setWeb3AuthPrivKey(privKey);
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  // handle disconnecting web3auth, and reset state data
  const handleDisconnectWeb3Auth = async () => {
    await web3AuthInstance.logout();
    await web3AuthInstance.init();
    setWeb3AuthPrivKey();
    setLokaMinerAgent();
    setCkBTCAgent();
    setUserInfo();
  };

  const fetchMinerDataFromCanister = async (agent) => {
    // get another userdata by username only for development
    // const anotherUserData = await agent.fetchMinerByUsername("luthfimedy");

    // get miner data from canister
    const res = await agent.getMinerData();

    // logrespon only for debuging
    // const logres = res?.ok || {};
    // const responCanister = {
    //   username: logres?.username || "null",
    //   logType: "fetchMinerDataFromCanister",
    // };
    // logErrorToBackend(responCanister, "logingLoginFunction");
    // console.log(responCanister, "<<<<< logingLoginFunction");
    // logrespon only for debuging

    if (!res?.ok) {
      return null;
    }

    setUserInfo((curr) => ({
      ...curr,
      ...normalizeMinerData(res.ok),
    }));
    return true;
  };

  const fetchMinerDataFromBackEnd = async (stateData) => {
    if (!stateData?.userInfo?.email && !stateData?.sessionId) return null;
    const userIdentity = getUserIdentity(web3AuthPrivKey);
    // get miner data from backend service
    const payload = {
      sessionId: stateData?.sessionId,
      email: stateData?.userInfo?.email,
      wallet: userIdentity.getPrincipal().toText(),
    };
    const res = await handleLogin(payload);

    // logrespon only for debuging
    // const logres = res?.data || {};
    // const stringLog = JSON.stringify({
    //   ...logres,
    //   logType: "fetchMinerDataFromBackEnd",
    // });

    // console.log(stringLog, "<<<<< logingLoginFunction");
    // logErrorToBackend(stringLog, "logingLoginFunction");
    // logrespon only for debuging
    // console.log(res, "<<<<< res");

    if (res.status === 200 && res.data) {
      setUserInfo((curr) => ({
        ...curr,
        ...res.data.user,
        accessToken: res.data.accessToken,
      }));
      return true;
    }
    return null;
  };

  // handling error and send to canister
  const logErrorToBackend = async (error, errorPosition) => {
    let errorMessage = "";

    if (error instanceof Error) {
      errorMessage = error?.message || "";
    } else if (typeof error === "string") {
      errorMessage = error;
    } else {
      try {
        errorMessage = JSON.stringify(error);
      } catch (jsonError) {
        errorMessage = "An unknown error occurred";
      }
    }

    const logPayload = {
      message: errorMessage,
      state: errorPosition,
    };

    const res = await storeLog(logPayload);

    if (res.status !== 201 && process.env.REACT_APP_DISPLAY_ERROR_POPUP) {
      message.error("failed store log");
      console.log("failed store log to backend");
    }
  };

  // miner data must be obtained from 2 sides (canister and BE (AWS))
  const handleSetUserInfo = async () => {
    try {
      setLoginLoading(true);

      const minerAgent = lokaMinerAgentCreation(web3AuthPrivKey);
      const ckbtcAgent = ckBTCAgentCreation(web3AuthPrivKey);
      setLokaMinerAgent(minerAgent);
      setCkBTCAgent(ckbtcAgent);

      // get miner data from canister
      const minerDataFromCanister = await fetchMinerDataFromCanister(
        minerAgent
      );

      // if failed getting data from canister, means user not registered yet
      if (!minerDataFromCanister) {
        throw new Error("failed get miner data from canister");
      }

      // get miner data from backend
      const minerDataFromBackend = await fetchMinerDataFromBackEnd(
        web3AuthInstance.state
      );

      // if failed getting data from backend, means user not registered yet
      if (!minerDataFromBackend) {
        throw new Error("failed get miner data from backend");
      }

      setLoginLoading(false);
    } catch (error) {
      console.log(error, "<<<<< waidaw");
      logErrorToBackend(error, "SetUserInfoFunction");
      setLoginLoading(false);
    }
  };

  // initiate web3auth connection, authentication process start here
  // if init web3auth success, web3auth instance will have privKey and trigger next useEffect

  useEffect(() => {
    setLoginLoading(true);
    async function initializeOpenlogin() {
      const sdkInstance = new OpenLogin(openLoginConfig);
      await sdkInstance.init();
      setWeb3AuthInstance(sdkInstance);
      setLoginLoading(false);
    }
    initializeOpenlogin();
  }, []);

  // will triggered when web3auth instance creation done,
  // also handle existing login data if user refresh the page
  // this use effect trigger next useEffect for get user data
  useEffect(() => {
    if (web3AuthInstance?.privKey) {
      setWeb3AuthPrivKey(web3AuthInstance.privKey);
    }
  }, [web3AuthInstance?.privKey]);

  // last useEffect in authentication process,
  // only trigger after success web3auth instance creation
  // will trigger function for getting user data
  useEffect(() => {
    if (web3AuthPrivKey) {
      handleSetUserInfo();
    }
  }, [web3AuthPrivKey]);

  return (
    <AppContext.Provider
      value={{
        web3AuthInstance,
        web3AuthPrivKey,
        handleConnectWeb3Auth,
        handleDisconnectWeb3Auth,
        lokaMinerAgent,
        setLokaMinerAgent,
        ckBTCAgent,
        setCkBTCAgent,
        userInfo,
        setUserInfo,
        loginLoading,
        fetchMinerDataFromCanister,
        fetchMinerDataFromBackEnd,
        logErrorToBackend,
        bitCoinPrices,
        setBitCoinPrices,
      }}
    >
      <Helmet>
        <title>Loka — Bitcoin Mining Pool for Everyone</title>
        <link rel="icon" type="png" href={smallIcon} />
        <link rel="apple-touch-icon" type="png" href={smallIcon} />
      </Helmet>
      <ConfigProvider theme={themeProvider}>{children}</ConfigProvider>
    </AppContext.Provider>
  );
};

export default AppProvider;
